<template>
  <div class="caseM110-wrap">
    <div class="caseM110head">
      <HeaderB></HeaderB>
    </div>
    <div class="caseM110content">
      <router-view/>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import HeaderB from '@/components/layout/headerB.vue'

export default {
  name: 'Home',
  data() {
    return {
      nowHome:''
    }
  },
  components: {
    HeaderB
  },
  created() {
    this.nowHome = this.$route.query.nowHome;
    this.EducationCase =this.$route.query.EducationCase
  }
}
</script>
<style lang="scss" scoped>
//.caseMain-wrap {
//  height: 100%;
//  box-sizing: border-box;
//  .caseMain-content{
//    width: 100%;
//    //calc用上就不用margin、padding了
//    height: calc(100% - 70px);
//    background-color: red;
//    overflow-y: auto;
//  }
//}
.caseM70-wrap{
  width: 100%;
  display: flex;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: hidden;
  .caseM70head{
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 9;
  }
  .caseM70content{
    width: 100%;
    overflow-y: auto;
    padding-top: 70px;
  }
}
.caseM110-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;

  .caseM110head{
    width: 100%;
    height: 110px;
    position: fixed;
    z-index: 0;
  }
  .caseM110content{
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 110px;
    bottom: 0px;
    z-index: 1;
    overflow-y: auto;
  }
}


</style>
